import { Button as AsurionButton } from '@soluto-private/mx-asurion-ui-react-v3';
import { SpeedTestStatus } from '@soluto-private/wixi-web-sdk';
import { withClickAnalytics } from '@streaming-advisor/analytics';
import { Container } from 'components/Main';
import { useFlow } from 'hooks/useFlow';
import { useNetworkScan } from 'hooks/useNetworkScan';
import { usePageContentData } from 'hooks/usePageContentData';
import { useRouterScan } from 'hooks/useRouterScan';
import { useSkipRouterTest } from 'hooks/useSkipRouterTest';
import { useUpdateMxHeader } from 'hooks/useUpdateMxHeader';
import { NetworkScanComplete } from 'pages/NetworkScan/components';
import { useEffect } from 'react';
import { RoutePath } from 'RoutePath';
import { NetworkTestCompleteProps } from 'types/PageProps';

const Button = withClickAnalytics(AsurionButton);

const TestComplete = () => {
  const { goTo } = useFlow();
  const { cancelScan, scanState } = useNetworkScan();
  const { scanState: routerScanState } = useRouterScan();

  const {
    backButton,
    continueButton,
    retestButton,
    continueToRouterSpeedButton,
  } = usePageContentData<NetworkTestCompleteProps>(RoutePath.TestComplete);

  // TODO: Remove feature flag check once A/B test is concluded.
  const skipRouterTest = useSkipRouterTest();

  useEffect(() => {
    const isResetDone = scanState === SpeedTestStatus.none;
    if (isResetDone) {
      goTo(RoutePath.NetworkTest);
    }
  }, [scanState, goTo]);

  useUpdateMxHeader({
    backTitle: backButton?.title,
    onBack: () => goTo(RoutePath.Welcome),
  });

  const renderContinueButton = () => {
    return skipRouterTest ? (
      <Button
        data-testid="TestComplete_ContinueBtn"
        color="secondary"
        onClick={() => {
          goTo(RoutePath.OnlineActivitiesQ1);
        }}
      >
        {continueButton.title}
      </Button>
    ) : (
      <Button
        data-testid="TestComplete_ContinueBtn"
        color="secondary"
        onClick={() => {
          const isRouterScanCompleted =
            routerScanState === SpeedTestStatus.completed;

          goTo(
            isRouterScanCompleted
              ? RoutePath.RouterSpeedCompleted
              : RoutePath.RouterSpeedProgress
          );
        }}
      >
        {continueToRouterSpeedButton.title}
      </Button>
    );
  };

  const ButtonRightGroup = (
    <>
      <Button
        data-testid="TestComplete_RetestBtn"
        variant="outline"
        color="secondary"
        onClick={cancelScan}
      >
        {retestButton.title}
      </Button>
      {renderContinueButton()}
    </>
  );

  return (
    <Container
      steps={['inprogress']}
      footer={{
        onBack: () => goTo(RoutePath.Welcome),
        backButtonText: backButton?.title,
        buttonRightGroup: ButtonRightGroup,
      }}
    >
      {/* TODO: Remove feature flag check once A/B test is concluded. */}
      <NetworkScanComplete skipRouterTest={skipRouterTest} />
    </Container>
  );
};

export default TestComplete;
